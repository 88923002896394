<template>
  <loader v-if="loading" />

  <section v-else id="events-list" class="route-content">
    <!-- Filters -->
    <menu class="filters sticky wrap">
      <!-- Event types -->
      <multi-select-list
        role="menuitem"
        :filter-options="eventFilters"
        @filters-changed="applyFilters"
      />

      <!-- Calendar | Filter by date-->
      <datepicker
        role="menuitem"
        class="datetime-picker--inline"
        :format="`'On: '${dateShortFormat}`"
        :time-picker="false"
        :min-date="today"
        :value="dateFilter"
        :constraints-format="dateInputFormat"
        :value-format="dateInputFormat"
        auto-close
        start-from-sunday
        value-type="Luxon"
        @input="onDateFilter"
      />

      <!-- Reset -->
      <button
        v-if="dateFilter"
        role="menuitem"
        class="filter--button filter--cancel"
        @click="clearFilters"
      >
        <i class="fas fa-times-square error--text"></i>
      </button>

      <events-list-legend
        v-if="listLegend.length"
        role="menuitem"
        :items="listLegend"
      />
    </menu>

    <button v-if="showScrollToTop" class="back-to-top" @click="scrollToTop">
      <span class="fas fa-arrow-up"></span>
    </button>

    <!-- Error message -->
    <p class="error--text text--centered" v-if="error" v-text="error" />

    <!-- Search Heading -->
    <template v-else-if="filtersActive">
      <hr class="divider primary-light" />
      <hr class="divider divider--md" />
      <h3>
        <i class="fas fa-calendar-day grey--text"></i>
        {{ searchLabel }}
        <span :class="searchLabelClass">Results</span>
      </h3>
      <p v-if="filtered.length === 0">
        <em>No results match your current filters</em>
      </p>
      <hr v-if="filtered.length === 0" class="divider divider--md" />
    </template>

    <!-- Events List -->
    <event-list-item
      v-for="(event, i) in filteredData"
      :event="event"
      :index="i"
      :is-assigned="isAssigned(event)"
      :key="`${i}-${event.id}`"
    />

    <!-- Events for the week -->
    <template v-if="filteredInWeek.length > 0">
      <h3>
        <i class="fas fa-calendar-week grey--text"></i>
        Week of
        <span class="accent--text" v-if="dateFilter">{{
          dateFilter.toFormat("MMM d")
        }}</span>
      </h3>
      <hr class="divider divider--md" />

      <p v-if="filteredInWeek.length === 0">
        <em>No results match your current filters</em>
      </p>

      <!-- Events List Items -->
      <event-list-item
        v-for="(event, i) in filteredDataWeek"
        is-online-class
        :event="event"
        :index="i"
        :is-assigned="isAssigned(event)"
        :key="`wk-${i}-${event.id}`"
      />
    </template>

    <div class="pagination-wrapper">
      <pagination
        v-model="page"
        :per-page="5"
        :records="filtered.length"
        @paginate="setPage"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import Pagination from "vue-pagination-2";
import EventListItem from "./EventListItem.vue";
import EventsListLegend from "./EventsList.Legend.vue";
import Loader from "./Loader.vue";
import EventsListMixin from "./mixins/events-list.mixin";
import MultiSelectList from "./MultiSelectList.vue";

export default {
  components: {
    Loader,
    EventListItem,
    EventsListLegend,
    MultiSelectList,
    Pagination
  },

  name: "EventsList",

  mixins: [EventsListMixin]
};
</script>

<style>
ul > li.disabled {
  padding-left: 0px;
  padding-right: 0px;
}
ul > li > a[disabled] {
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  height: auto;
}

.pagination-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: black !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
</style>
